import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default () => (
  <>
    <Seo title='Page Not Found' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header="Xin lỗi, trang bạn vừa truy cập hiện không tồn tại."
            subheader='Có thể nội dung bạn muốn tìm đã đã bị xoá hoặc đường dẫn bị nhập sai.'
          />
        </Section>
        <Section>
          <Button variant='primary' as={Link} to='/'>
            Quay trở lại trang chủ
          </Button>
        </Section>
      </Main>
    </Stack>
  </>
)
